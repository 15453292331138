.App {
  text-align: left;
  padding: 1em;
  margin: 1em;
  border: 2px solid #d3d3d3;
  border-radius: 0.5em;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  margin-top: 50px;
}

.form {
  padding: 1em;
}

label {
  /*display: flex;
  font-weight: 100; */
}

#country_code {
  max-width: 25%;
}

#sub-button {
  width: 40%;
  margin-right: 20px;
  color: white;
  background-color: green;
}

#help-button {
  width: 15%;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.help {
  border: 2px solid #d3d3d3;
  border-radius: 0.5em;
  padding: 1em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* To remove arrows from number fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
